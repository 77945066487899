.featured{
    width:100%;
    max-width:1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    z-index: 1;
}


.featuredItem{
    /* flex: 3; */
    /* width: 354px; */
    position: relative;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
}
.featuredImg{
    width: 100%;
    object-fit: cover;   
}

.featuredtitles{
    position: absolute;
    bottom: 20px;
    left: 20px;
}